import React from "react";
import "./style.css";
import BakedByDrake from "./BakedByDrake.png";
import Background from "./purty-wood.png";

export default function First(props) {
    const sectionStyle = {
        backgroundImage: `url(${Background})`,
        backgroundAttachment: "fixed",
      };
    return (
        <div id="pageOne" className="pageOne" style={sectionStyle}>
          <img className="logo" src={BakedByDrake} alt="logo"></img>
          <button onClick={props.homeToggle}><i className="fas fa-angle-double-down"></i></button>
        </div>
    )
}