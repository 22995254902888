const Pictures = 

[
    {
        src: "/pictures/baby.jpg",
        text: "Baby Shower"
    },
    {
        src: "/pictures/cactus.jpg",
        text: "Cactus-Themed Baby Shower"
    },
    {
        src: "/pictures/depression.jpg",
        text: "Depression Awareness Cookie Sale"
    },
    {
        src: "/pictures/mom.jpg",
        text: "Mom Tattoo"
    },
    {
        src: "/pictures/monster.jpg",
        text: "Monster-Themed Party"
    },
    {
        src: "/pictures/newyear.jpg",
        text: "Happy New Year!"
    },
    {
        src: "/pictures/urban.jpg",
        text: "Massive Street Cred"
    },
    {
        src: "/pictures/woodsy.jpg",
        text: "A Woodsy Affair!"
    },
    {
        src: "/pictures/nativeamerican.jpg",
        text: "Native American"
    },
    {
        src: "/pictures/dragons.jpg",
        text: "Dragons Love Tacos!"
    },
    {
        src: "/pictures/baptism.jpg",
        text: "Baptism"
    }
]
export default Pictures;