import React from "react";
import "./style.css";

export default function Footer() {
    return (
    <div>
    <footer className="footer"> 
        <div className="container">
        <span>Copyright <i class="fa fa-copyright"></i> Baked By Drake</span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="mailto:bakedbydrake@gmail.com"><i class="fas fa-envelope-square" aria-hidden="true"></i></a></span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="https://www.facebook.com/bakedbydrake" target="_blank" rel="noopener noreferrer" ><i class="fab fa-facebook-square" aria-hidden="true"></i></a></span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="https://www.instagram.com/bakedbydrake/" target="_blank" rel="noopener noreferrer" ><i class="fab fa-instagram" aria-hidden="true"></i></a></span>
        <span>&nbsp;&nbsp;</span>
        <span><a href="https://paypal.me/bakedbydrake" target="_blank" rel="noopener noreferrer" ><i class="fab fa-paypal" aria-hidden="true"></i></a></span>
        </div>
    </footer>
    </div>
    )
}