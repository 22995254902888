import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import BakedByDrake from "./BakedByDrake.png";


export default function Header(props) {

    return (
        <header className="header">
        <Link to="/" className={window.location.pathname === "/"}><img className="mini-img" src={BakedByDrake} alt="logo"></img></Link>
          <ul className="nav justify-content-center mt-3">
              <li className="nav-item">
                    <Link to="/about" className={window.location.pathname === "/about" ? "nav-link active" : "nav-link"}>About</Link>
              </li>
              <li className="nav-item">
                    <Link to="/gallery" className={window.location.pathname === "/gallery" ? "nav-link active" : "nav-link"}>Gallery</Link>
              </li>
              <li className="nav-item">
                    <Link to="/order" className={window.location.pathname === "/order" ? "nav-link active" : "nav-link"}>Order</Link>
              </li>
              <li className="nav-item">
                  <button className="naked" onClick={props.toggle}>Contact</button>
              </li>
          </ul>
      </header>
    )
}