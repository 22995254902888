import React from "react";
import "./style.css";
import Pictures from "./pictures.js"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function Gallery() {
    return(
    <div id="gallery" className="gallery mx-auto">
        <Carousel>
        {Pictures.map(picture => (
            <div>
                <img src={picture.src} alt=""/>
                <p className="legend">{picture.text}</p>
            </div>
        ))}
        </Carousel>
    </div>
    )
}