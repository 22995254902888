import React,{ Component } from 'react';
import {BrowserRouter as Router, Route, Routes, Switch} from "react-router-dom"
import "./style.css";
import Footer from "./Footer";
import Header from "./Header";
import News from "./News";
import About from "./About";
import Gallery from "./Gallery";
import Order from "./Order";
import Background from "./purty-wood.png";
import First from "./First";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.homeToggle = this.homeToggle.bind(this);
    this.state = {
      modal:false,
      home:true
    };
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  homeToggle() {
    this.setState({home: false})
  }
  render() {
    const sectionStyle = {
      backgroundImage: `url(${Background})`,
      backgroundAttachment: "fixed",
    };
    const modalStyle = {
      backgroundColor: "#66ADDD",
      color: "white",
      border: "none",
      fontFamily: "Lily Script One"
      };
    const modalStyle2 = {
      backgroundColor: "#3d3d3d",
      color: "#66ADDD",
      border: "none"
    };
    const modalButton = {
      backgroundColor: "white",
      color: "#3d3d3d",
    }
  return (
    <Router>
      <div style={sectionStyle} className="body">
        {this.state.home === true && <First homeToggle={this.homeToggle} />}
        {this.state.home === false &&
        <div id="mainPage" className="mainPage" style={sectionStyle}>
          <Header toggle={this.toggle} />
              <div className="middle">
              <Route exact path="/" component={News} />
              <Route exact path="/about" component={About} />
              <Route exact path="/gallery" component={Gallery} />
              <Route exact path="/order" component={Order} />
              </div>
          <Footer />
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader style={modalStyle} toggle={this.toggle}>Contact Info</ModalHeader>
            <ModalBody style={modalStyle2}>
              <p>Contact Christy with any questions or to order!</p>
              <p><a href="mailto:bakedbydrake@gmail.com"><i className="fas fa-envelope-square" aria-hidden="true"></i>&nbsp; &nbsp; bakedbydrake@gmail.com</a></p>
              <p><a href="https://www.facebook.com/bakedbydrake" target="_blank" rel="noopener noreferrer" ><i className="fab fa-facebook-square" aria-hidden="true"></i>&nbsp; &nbsp; Facebook</a></p>
              <p><a href="https://www.instagram.com/bakedbydrake/" target="_blank" rel="noopener noreferrer" ><i className="fab fa-instagram" aria-hidden="true"></i>&nbsp; &nbsp; Instagram</a></p>
              <p><a href="https://paypal.me/bakedbydrake" target="_blank" rel="noopener noreferrer" ><i className="fab fa-paypal" aria-hidden="true"></i>&nbsp; &nbsp; Pay on PayPal</a></p>
            </ModalBody>
            <ModalFooter style={modalStyle}>
              <Button style={modalButton} onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
        }
      </div>
    </Router>
    );
  }
}



