import React from "react";
import "./style.css";
import Market from "./pictures/market.jpg"

export default function News() {
    return (
        <div id="news" className="news mx-auto">
            <div className="container mx-auto mt-3">
            <img src={Market} alt="market" className="img-fluid largePic mx-auto"></img>
            </div>
        </div>
    )
}