import React from "react";
import "./style.css";
import JotformEmbed from 'react-jotform-embed';

export default function Order() {
    return (
        <div id="order" className="order mx-auto mt-2 mb-4">
            <h2 className="text-center">Order Info</h2>
            <div className="ordering">
            <div className="order1">
                <p>Check My Calendar and Order via Jotjorm</p>
            </div>
            <div className="jotform">
            <JotformEmbed scroll="true" src="https://form.jotform.com/220005337127039" />
            </div>
            <div className="order3">
                <p><a href="mailto:bakedbydrake@gmail.com">Email Christy for Details</a></p>
            </div>
            </div>
        </div>
    )
}